.stats-container {
    text-align: center;
    padding: 20px;
  }
  
  .stats-heading {
    font-size: 24px;
    margin-bottom: 20px;
  }
  
  .stats-grid {
    display: flex;
    justify-content: space-around;
    margin: 0 auto;
    max-width: 800px;
  }
  
  .stat-item {
    text-align: center;
    margin: 10px;
  }
  
  .stat-number {
    font-size: 36px;
    color: #FFA500; /* Orange color */
  }
  
  .stat-description {
    font-size: 16px;
    color: #333;
    margin-top: 5px;
  }