.mv-container {
    display: flex;
    justify-content: space-around;
    text-align: center;
    padding: 20px;
  }
  
  .mv-item {
    margin: 10px;
    flex: 1;
  }
  
  .mv-icon {
    /* width: 100px; */
    height: 100px;
    margin-bottom: 10px;
  }
  
  .mv-title {
    font-size: 24px;
    margin: 10px 0;
    color: #FFA500; /* Orange color */
  }
  
  .mv-description {
    font-size: 16px;
    color: #333;
  }
  