.video-hero {
  position: relative;
  width: 100vw;
  height: 77vh;
  background-color: var(--brand-dark-blue);
}
.video-hero-player-container {
  object-fit: cover;
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.video-hero-player {
  margin: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}


.navbar{
  position: absolute;
    margin: 0 !important;
    color: #fff;
    top: 0;
    left: 0;
    right: 0;
    z-index: 10;
    padding: 30px 0;
    transition: all ease 0.5s;
    border-bottom: 3px solid rgba(68, 84, 106, 0.2);
    display: flex;
    align-items: center;
}

a{
  color: var(--inherit-default);
  font-weight: 500;
  text-transform: uppercase;
  display: block;
  position: relative;
  padding: 28px 28px;
  padding-top: 0px;
  text-decoration: none;
  letter-spacing: 0.85px;
  transition: all ease 0.5s;
}

.a-footer{
  color: white;
  font-weight: 500;
  text-transform: uppercase;
  display: block;
  position: relative;
  padding: 28px 28px;
  padding-top: 0px;
  text-decoration: none;
  letter-spacing: 0.85px;
  transition: all ease 0.5s;
  text-align: left;
  width: 225px;
}


.testimonial{
  font-weight: 200;
    font-size: 28px;
    line-height: 200%;
    color: var(--brand-dark-blue);
    font-style: italic;
    padding: 60px;
    padding-top: 0px;
    padding-bottom: 0px;
}

.client_name{
  margin-top: 40px;
    font-weight: 300;
    color: var(--brand-dark-blue);
}

div:where(.swal2-container) button:where(.swal2-styled).swal2-confirm{
  background: rgb(8, 55, 241) !important;
}

.button-active-large{
  background: rgb(8, 55, 241) !important;
  width:80%;
  padding: 5px;
  height:35px;
  border-radius:4px;
  margin-bottom: 10px;
  border:none;
  color:white;
  font-weight: bold;
}

.userinput{
  width:80%;
  height:35px;
  border-radius:4px;
  margin-bottom: 10px;
}

.rbc-month-row {
  display: flex;
  position: relative;
  flex-direction: column;
  flex: 1 0;
    flex-basis: 0%;
  flex-basis: 100px !important;
  overflow: hidden;
  height: 20px !important;
}

  @media (max-width: 900px) {
    .hide-on-small-screen {
      display: none;
    }
  }

  @media (max-width: 899px) {
    .hide-on-large-screen {
      display: block;
    }
  }
  
  @media (min-width: 900px) {
    .hide-on-large-screen {
      display: none !important;
    }
  }
