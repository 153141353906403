body {
    font-family: Arial, sans-serif;
    background-color: #f8f9fa;
    color: #333;
    margin: 0;
  }
  
  .user-table-container {
    max-width: 800px;
    margin: 0 auto;
    background: #fff;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  }
  
  h1 {
    margin-bottom: 20px;
  }
  
  .user-table {
    width: 100%;
    border-collapse: collapse;
  }
  
  .user-table th, .user-table td {
    padding: 12px 15px;
    text-align: left;
  }
  
  .user-table th {
    background-color: #f2f2f2;
  }
  
  .user-table tbody tr {
    border-bottom: 1px solid #e2e2e2;
  }
  
  .user-table tbody tr:last-child {
    border-bottom: none;
  }
  
  .user-info {
    display: flex;
    align-items: center;
  }
  
  .avatar {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    margin-right: 10px;
  }
  
  .status {
    padding: 5px 10px;
    border-radius: 12px;
    font-size: 12px;
    color: #fff;
  }
  
  .status-active {
    background-color: #28a745;
  }
  
  .status-waiting {
    background-color: #ffc107;
    color: #333;
  }



  .create-button {
    border: none;
    background-color:   #00cc7f;
    color: white;
    border-radius: 4px;
    font-size: 20px;
    cursor: pointer;
    padding: 4px;
  }

  
  .delete-button {
    border: none;
    background-color: #dc3545;
    color: white;
    border-radius: 4px;
    font-size: 20px;
    cursor: pointer;
    padding: 4px;
  }

  .edit-button {
    border: none;
    background-color: #0258cd;
    color: white;
    border-radius: 4px;
    font-size: 20px;
    cursor: pointer;
    padding: 4px;
  }
  
  @media (max-width: 768px) {
    .user-table th, .user-table td {
      padding: 10px;
    }
  
    .user-info {
      flex-direction: column;
      align-items: flex-start;
    }
  
    .avatar {
      margin-bottom: 5px;
    }
  }
  